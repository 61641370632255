// API
import * as api from "@startapp/laira-user-ssr-api";
// Libraries
import { inspect } from "util";
import Strings from "./Strings";

export interface IError {
	type: api.ErrorType;
	message: string;
}

export function handleError( e: any) {
	if (process.env.APP_ENV !== "production") {
		console.error(inspect(e));
	}

	const apiError: { type?: api.ErrorType, message?: string } = e;

	if (apiError.message && apiError.type && apiError.type !== api.ErrorType.Connection && apiError.type !== api.ErrorType.Fatal) {
		return apiError.message;
	}

	return process.env.APP_ENV === "production" ? Strings.error.default : inspect(e);
}

export function treatError(e: any) {
	if (typeof e === "object") {
		if (Object.keys(api.ErrorType).includes(e.type) && e.message) {
			const error: IError = {
				type: e.type,
				message: treatErrorMessage(e.message),
			};

			if (process.env.APP_ENV === "production" && error.type === api.ErrorType.Fatal) {
				error.message = Strings.error.default;
			}

			return error;
		}

		return {
			type: api.ErrorType.Fatal,
			message: treatErrorMessage(e),
		};
	} else {
		return {
			type: api.ErrorType.Fatal,
			message: treatErrorMessage(e),
		};
	}
}

function treatErrorMessage(errorMessage: any): string {
	if (typeof errorMessage === "object") {
		return JSON.stringify(errorMessage);
	} else {
		return `${errorMessage}`;
	}
}
