import React, { useEffect, useMemo, useState } from "react";
import * as api from "@startapp/laira-user-ssr-api";

// MARK: Stores
import { observer } from "mobx-react-lite";
import { treatError, IError } from "../resources/Errors";

// MARK: libs
import Cookies from "js-cookie";

type OnSuccess = () => void;
type OnError = (error: any) => void;

interface UserAuthStore {
	user: api.User | null;
	loading: boolean;
	autoLoaded: boolean;
	error: IError | null;
	login(
		email: string,
		password: string,
		onSuccess: OnSuccess,
		onError?: OnError,
	): void;
	logout(onSuccess: OnSuccess): void;
	isLoggedOut: boolean;
	fetchUser: () => void;
	isShopOwner: boolean;
	deleteUser(onSuccess: OnSuccess): void;
}

const userAuthContext = React.createContext<UserAuthStore | null>(null);

export const UserAuthProvider = observer((props) => {
	const [loading, setLoading] = useState(false);
	const [autoLoaded, setAutoLoaded] = useState(false);
	const [error, setError] = useState<IError | null>(null);
	const [user, setUser] = useState<api.User | null>(null);

	const startLoading = () => setLoading(true);
	const stopLoading = () => setLoading(false);
	const clearError = () => setError(null);

	const login = async (
		email: string,
		password: string,
		onSuccess?: OnSuccess,
		onError?: OnError,
	) => {
		clearError();
		startLoading();

		try {
			const data = await api.userLogin(email, password);
			setUser(data);
			Cookies.set("current_user", data);
			onSuccess();
		} catch (e) {
			setError(treatError(e));
			onError(e);
		} finally {
			stopLoading();
		}
	};

	const logout = async (onSuccess?: () => void) => {
		clearError();
		startLoading();

		try {
			await api.logout();
			Cookies.remove("current_user");
			setUser(null);
			if (onSuccess) {
				onSuccess();
			}
		} catch (e) {
			setError(treatError(e));
		} finally {
			stopLoading();
		}
	};

	const deleteUser = async (onSuccess?: () => void) => {
		clearError();
		startLoading();

		try {
			await api.deleteUserPermanently();
			Cookies.remove("current_user");
			setUser(null);
			if (onSuccess) {
				onSuccess();
			}
		} catch (e) {
			setError(treatError(e));
		} finally {
			stopLoading();
		}
	};

	const fetchUser = async () => {
		startLoading();
		try {
			const data = await api.getCurrentUser();
			setUser(data);
			Cookies.set("current_user", data);
			console.log(data);
		} catch (e) {
			setError(treatError(e));
		} finally {
			stopLoading();
		}
	};

	useEffect(() => {
		const fetch = async () => {
			await fetchUser();
			setAutoLoaded(true);
		};
		fetch();
	}, []);

	const isShopOwner = useMemo(() => user && !!user.store, [user, loading]);

	const isLoggedOut = useMemo(() => {
		return !!error && error.type === api.ErrorType.NotLoggedIn;
	}, [error, user]);

	React.useEffect(() => {}, [user]);
	const exports = {
		user,
		error,
		loading,
		isLoggedOut,
		login,
		logout,
		fetchUser,
		isShopOwner,
		autoLoaded,
		deleteUser,
	};

	return (
		<userAuthContext.Provider value={exports}>
			{props.children}
		</userAuthContext.Provider>
	);
});

export function useUserAuth() {
	const store = React.useContext(userAuthContext);
	if (!store) {
		throw new Error("Cannot Access Store outside it's context");
	}
	return store;
}
